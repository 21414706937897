/* @font-face {
  font-family: "Satoshi";
  src: url("../public/fonts/Satoshi-Medium.ttf") format("ttf"),
    url("../public/fonts/Satoshi-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
} */

/* @font-face {
  font-family: "Satoshi";
  src: url("../public/fonts/Satoshi-Bold.ttf") format("ttf"),
    url("../public/fonts/Satoshi-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
} */
@font-face {
  font-family: "Satoshi";
  src: url("/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Satoshi";
  src: url("/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
